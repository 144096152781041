import React from 'react'
import Header from '../element/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../element/Footer'

const MainLayout = () => {
  return (
    <>
        <Header/>
        <Outlet/>
        <Footer/>

    </>
  )
}

export default MainLayout
