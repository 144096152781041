import React from 'react'
import styles from './contact-content.module.scss'
import { Container} from 'react-bootstrap';

const PrivacyContent = () => {
  return (
    <section className={styles.privacy_content}>
      <Container>
        <h2>Lorem Ipsum is simply dummy</h2>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        <h3>Lorem Ipsum is simply dummy</h3>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        <ul>
          <li>Lorem Ipsum is simply dummy</li>
          <li>Lorem Ipsum is simply dummy</li>
          <li>Lorem Ipsum is simply dummy</li>
        </ul>
      </Container>
    </section>
  )
}

export default PrivacyContent
