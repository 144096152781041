import React from 'react'
import styles from './industries.module.scss'
import { Container } from 'react-bootstrap';
import { AtumobileIcn } from '../../assets/images'

const Industries = () => {
  return (
    <div className={styles.industry}>
      <Container>
        <div className="sub-heading text-center">
          <h2>Shaping Excellence Across Industries</h2>
          <p>Gurudev Abrasives, has consistently demonstrated its expertise and commitment to quality in various sectors.<br className="d-none d-lg-block" /> Explore how Gurudev Abrasives is making its mark in the following industries:</p>
        </div>
        <div>
          <ul className={styles.industry_ul_frst}>
            <li>
              <div className={styles.industry_ul_itm}>
                <span className={styles.industry_itm_img}>
                  <img src={AtumobileIcn} alt="Atumobile icon" />
                </span>
                <span className={styles.industry_itm_txt}>Automobile</span>
              </div>
            </li>
            <li>
              <div className={styles.industry_ul_itm}>
                <span className={styles.industry_itm_img}>
                  <img src={AtumobileIcn} alt="Atumobile icon" />
                </span>
                <span className={styles.industry_itm_txt}>Petrochemical</span>
              </div>
            </li>
            <li>
              <div className={styles.industry_ul_itm}>
                <span className={styles.industry_itm_img}>
                  <img src={AtumobileIcn} alt="Atumobile icon" />
                </span>
                <span className={styles.industry_itm_txt}>Aurospace</span>
              </div>
            </li>
            <li>
              <div className={styles.industry_ul_itm}>
                <span className={styles.industry_itm_img}>
                  <img src={AtumobileIcn} alt="Atumobile icon" />
                </span>
                <span className={styles.industry_itm_txt}>Construcon</span>
              </div>
            </li>
          </ul>
          <ul className={styles.industry_ul_scnd}>
            <li>
              <div className={styles.industry_ul_itm}>
                <span className={styles.industry_itm_img}>
                  <img src={AtumobileIcn} alt="Atumobile icon" />
                </span>
                <span className={styles.industry_itm_txt}>Forging</span>
              </div>
            </li>
            <li>
              <div className={styles.industry_ul_itm}>
                <span className={styles.industry_itm_img}>
                  <img src={AtumobileIcn} alt="Atumobile icon" />
                </span>
                <span className={styles.industry_itm_txt}>Wagon & Ship building</span>
              </div>
            </li>
            <li>
              <div className={styles.industry_ul_itm}>
                <span className={styles.industry_itm_img}>
                  <img src={AtumobileIcn} alt="Atumobile icon" />
                </span>
                <span className={styles.industry_itm_txt}>Metallurgy</span>
              </div>
            </li>
            <li>
              <div className={styles.industry_ul_itm}>
                <span className={styles.industry_itm_img}>
                  <img src={AtumobileIcn} alt="Atumobile icon" />
                </span>
                <span className={styles.industry_itm_txt}>Fabricaon</span>
              </div>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  )
}

export default Industries
