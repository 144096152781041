import React from 'react'
import styles from './experience-unmatched.module.scss'
import { Container, Row, Col } from 'react-bootstrap';
import { HmgExpImg, HmgExpBgImg } from '../../assets/images';
import { Link } from "react-router-dom";

const ExperienceUnmatched = ({experienceRef}) => {
  return (
    <section ref={experienceRef} className={styles.experience_unmatched}>
      <Container>
        <Row className="align-items-center">
          <Col lg="6">
            <div className={styles.experience_img_sec}>
              <img src={HmgExpImg} alt="Experience" className={styles.experience_img} />
              <img src={HmgExpBgImg} alt="Experience Bg" className={styles.experience_img_bg} />
            </div>
          </Col>
          <Col lg="6">
            <div className="sub-heading">
              <h2>Experience Unmatched <span>Performance</span></h2>
              <p>Contemporary world is defined by ambitious infrastructure development, high end technology integration and high demand for efficiency, all in an uncompromising fashion. South Pacific region, with India as its prominent poster boy, is thunder rumbling the globe with soaring aspirations exerting relentlessly to set off its long desire to become the epicentre of economic build out. Abrasives industry is indispensable for catalysing the robust growth of any economy contributing significantly in state of the art infrastructure development that are based on strong values of high precision and perfection. </p>
              <p>Industries such as transportation and electronic equipment are estimated to record robust growth due to higher demand for automobiles and electronic appliances. Apart from this use of abrasives in construction, cleaning, maintenance, power generation and medical devices are expected to rise significantly in the coming years thus driving demand in the global abrasives market.</p>
              <Link to="/" className="theme-btn btn">Learn More</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ExperienceUnmatched
