import React, { useRef } from 'react'
import TermsBanner from '../components/terms/TermsBanner'
import TermsContent from '../components/terms/TermsContent'

const Terms = () => {
  return (
    <>
      <TermsBanner />
      <TermsContent />
    </>
  )
}

export default Terms
