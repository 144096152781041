import React from 'react'
import styles from './our-clients.module.scss'
import { LogoShape, TstLogo } from '../../assets/images'
import { Container, Row, Col } from 'react-bootstrap';

const OurClients = () => {
  return (
    <div className={styles.our_clients}>
      <Container className={styles.our_clients_contnt}>
        <Row className="align-items-center">
          <Col xl="5" lg="12">
            <div className="sub-heading text-center text-xl-start">
              <img src={LogoShape} alt="logo shape" />
              <h2 className="text-white">Our Prestigious Clients</h2>
              <p className="text-white mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
          </Col>
          <Col xl="7" lg="12">
            <div className={styles.our_clients_right_row}>
              <div className={styles.our_clients_right_row_col}>
                <div className={styles.our_clients_itm_tp}>
                  <div className={styles.our_clients_itm}>
                    <img src={TstLogo} alt="test logo" />
                    <p className="mb-0">SCRUM DIGITAL</p>
                  </div>
                  <div className={styles.our_clients_itm}>
                    <img src={TstLogo} alt="test logo" />
                    <p className="mb-0">SCRUM DIGITAL</p>
                  </div>
                </div>
              </div>
              <div className={styles.our_clients_right_row_col}>
                <div className={styles.our_clients_itm}>
                  <img src={TstLogo} alt="test logo" />
                  <p className="mb-0">SCRUM DIGITAL</p>
                </div>
                <div className={styles.our_clients_itm}>
                  <img src={TstLogo} alt="test logo" />
                  <p className="mb-0">SCRUM DIGITAL</p>
                </div>
              </div>
              <div className={styles.our_clients_right_row_col}>
                <div className={styles.our_clients_itm_tp}>
                  <div className={styles.our_clients_itm}>
                    <img src={TstLogo} alt="test logo" />
                    <p className="mb-0">SCRUM DIGITAL</p>
                  </div>
                  <div className={styles.our_clients_itm}>
                    <img src={TstLogo} alt="test logo" />
                    <p className="mb-0">SCRUM DIGITAL</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OurClients
