import React from 'react'
import styles from './home-banner.module.scss'
import { Container, Row, Col } from 'react-bootstrap';
import { HmgBannerImg, HmgBannerBgImg } from '../../assets/images';
import { IoIosArrowDown } from "react-icons/io";

const HomeBanner = ({ navigateToNextSection }) => {
  return (
    <section className={styles.home_banner}>
      <Container>
        <Row className="justify-content-center">
          <Col lg="11">
            <div className={`${styles["home_banner_contnt"]} text-center`}>
              <h1>WHERE PRECISION MEETS <br /><span>PERFECTION</span></h1>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              <img src={HmgBannerImg} alt="banner" className={styles.home_banner_img} />
            </div>
          </Col>
        </Row>
      </Container>
      <img src={HmgBannerBgImg} alt="banner bg" className={styles.home_banner_bg_img} />
      <button onClick={navigateToNextSection} className={styles.down_arrow_icn}><i><IoIosArrowDown /></i></button>
    </section>
  )
}

export default HomeBanner
