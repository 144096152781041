import React from 'react'
import styles from './typesetting.module.scss'
import { Container, Row, Col } from 'react-bootstrap';
import { ItmImg1 } from '../../assets/images';

const Typesettings = () => {
  return (
    <div className={styles.type_settings}>
      <Container>
        <Row className="justify-content-center">
          <Col xxl="9" lg="12">
            <div className="sub-heading">
              <h2>Lorem Ipsum is simply dummy text of the printing and typesetting</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
            </div>
          </Col>
          <Col xxl="3" lg="4" md="6" sm="6">
            <div className={styles.type_settings_itm}>
              <span className={styles.itm_img}>
                <img src={ItmImg1} alt="" />
              </span>
              <h4>Lorem Ipsum is simply dummy printing and typesetting</h4>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
            </div>
          </Col>
          <Col xxl="3" lg="4" md="6" sm="6">
            <div className={styles.type_settings_itm}>
              <span className={styles.itm_img}>
                <img src={ItmImg1} alt="" />
              </span>
              <h4>Lorem Ipsum is simply dummy printing and typesetting</h4>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
            </div>
          </Col>
          <Col xxl="3" lg="4" md="6" sm="6">
            <div className={styles.type_settings_itm}>
              <span className={styles.itm_img}>
                <img src={ItmImg1} alt="" />
              </span>
              <h4>Lorem Ipsum is simply dummy printing and typesetting</h4>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
            </div>
          </Col>
          <Col xxl="3" lg="4" md="6" sm="6">
            <div className={styles.type_settings_itm}>
              <span className={styles.itm_img}>
                <img src={ItmImg1} alt="" />
              </span>
              <h4>Lorem Ipsum is simply dummy printing and typesetting</h4>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
            </div>
          </Col>
          <Col xxl="3" lg="4" md="6" sm="6">
            <div className={styles.type_settings_itm}>
              <span className={styles.itm_img}>
                <img src={ItmImg1} alt="" />
              </span>
              <h4>Lorem Ipsum is simply dummy printing and typesetting</h4>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Typesettings
