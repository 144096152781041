import React from 'react'
import styles from './abrasive-solution.module.scss'
import { Container, Row, Col } from 'react-bootstrap';
import { AbrasiveSolutionsImg1, AbrasiveSolutionsImg2, AbrasiveSolutionsImg3 } from '../../assets/images';
import { Link } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";


const AbrasiveSolution = () => {
  return (
    <section className="full-container">
      <div className={styles.abrasive_solutions}>
        <Container className={styles.abrasive_solutions_contnt}>
          <Row className="text-center text-xl-start">
            <Col xl="6" lg="12">
              <div className="sub-heading">
                <h2 className="text-white mb-0">India’s Finest Abrasive<br className="d-none d-lg-block" /> Solutions</h2>
              </div>
            </Col>
            <Col xl="6" lg="12">
              <p className={`${styles["des_text"]} text-white`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
            </Col>
          </Row>
          <Row className={`${styles["abrasive_solutions_btm"]} justify-content-center`}>
            <Col lg="4" md="6" sm="6">
              <div className={styles.abrasive_solutions_itm}>
                <div className={styles.abrasive_solutions_itm_contnt}>
                  <h3>High Carbon <span>Steel Shots</span></h3>
                  <p>Our steel shots at Gurudev Abrasives set the benchmark for abrasive blasting materials, meticulously crafted for optimal performance</p>
                  <Link to="/">Learn <br className="d-none d-lg-block" /> More<span><GoArrowUpRight /></span></Link>
                </div>
                <img src={AbrasiveSolutionsImg1} className={styles.abrasive_solutions_itm_img} alt="Abrasive Solutions" />
              </div>
            </Col>
            <Col lg="4" md="6" sm="6">
              <div className={styles.abrasive_solutions_itm}>
                <div className={styles.abrasive_solutions_itm_contnt}>
                  <h3>High Carbon <span>Steel Grits</span></h3>
                  <p>Gurudev Abrasives’ Steel Grit is a standout in the abrasive materials industry, engineered for precision and durability.</p>
                  <Link to="/">Learn <br className="d-none d-lg-block" /> More<span><GoArrowUpRight /></span></Link>
                </div>
                <img src={AbrasiveSolutionsImg2} className={styles.abrasive_solutions_itm_img} alt="Abrasive Solutions" />
              </div>
            </Col>
            <Col lg="4" md="6" sm="6">
              <div className={styles.abrasive_solutions_itm}>
                <div className={styles.abrasive_solutions_itm_contnt}>
                  <h3>Black <span>Iron Powder</span></h3>
                  <p>With more than 98% Fe content & insignificent fraction of sulphur & phosphorus. The black iron powder produced by gurudev abrasives</p>
                  <Link to="/">Learn <br className="d-none d-lg-block" /> More<span><GoArrowUpRight /></span></Link>
                </div>
                <img src={AbrasiveSolutionsImg3} className={styles.abrasive_solutions_itm_img} alt="Abrasive Solutions" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section >
  )
}

export default AbrasiveSolution
