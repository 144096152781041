import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MainRoutes } from "./routes/MainRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.scss';


function App() {
  const routes = createBrowserRouter(MainRoutes)
  return (
    <>
      <RouterProvider router = {routes}/>
    </>
  );
}

export default App;
