import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Logo } from '../../assets/images';
import { IoMdCall } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import "./Header.scss";
const Header = () => {
  const { pathname } = useLocation();
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    let elementId = document.getElementById("header");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  }, [pathname]);
  const dd = () => {
    setMenu(!menu)
  }
  useEffect(() => {
    if (menu) {
      document.body.classList.add("menu-open-body")
    } else {
      document.body.classList.remove("menu-open-body")
    }
    return () => document.body.classList.remove("menu-open-body")
  }, [menu])
  return (
    <header className="hmg-header fixed-top" id="header">
      <Navbar expand="lg">
        <div className="full-container">
          <Link to="/" className="navbar-brand pt-0 pb-0 me-0">
            <img src={Logo} alt="logo" />
          </Link>
          <Navbar.Toggle className={`${menu ? 'open' : 'close'}`} aria-controls="basic-navbar-nav" onClick={dd} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto hmg-header-left">
              <Link to="/" className="nav-link">Home</Link>
              <Link to="/about-us" className="nav-link">About</Link>
              <Link to="/contact-us" className="nav-link">Contact Us</Link>
            </Nav>
            <Nav>
              <div className="hmg-header-right">
                <Link to="/" className="theme-btn btn">Conatct Us</Link>
                <span><Link to="tel:0123456789"><IoMdCall /></Link></span>
              </div>
              <div className="d-block d-lg-none">
                <div className="d-flex social-lnks-hdr justify-content-center mt-5">
                  <Link to="#" target="_blank"><FaFacebookF /></Link>
                  <Link to="#" target="_blank"><FaTwitter /></Link>
                  <Link to="#" target="_blank"><FaInstagram /></Link>
                  <Link to="#" target="_blank"><FaYoutube /></Link> 
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  )
}

export default Header
