import React, { useRef } from 'react'
import HomeBanner from '../components/home/HomeBanner'
import ExperienceUnmatched from '../components/home/ExperienceUnmatched'
import AbrasiveSolution from '../components/home/AbrasiveSolution'
import Cta from '../components/home/Cta'
import Typesettings from '../components/home/Typesettings'
import OurClients from '../components/home/OurClients'
import Industries from '../components/home/Industries'
import Subscribe from '../components/home/Subscribe'

const Home = () => {
  const experienceRef = useRef();
  const navigateToNextSection = () =>{
      console.log(experienceRef.current.offsetTop)
      window.scrollTo({
        top:experienceRef.current.offsetTop, behavior: "smooth"
      });
  }
  return (
   <>
     <HomeBanner navigateToNextSection = {navigateToNextSection} />
    <ExperienceUnmatched experienceRef = {experienceRef}/>
    <AbrasiveSolution/>
    <Cta/>
    <Typesettings/>
    <OurClients/>
    <Industries/>
    <Subscribe/>
    </>
  )
}

export default Home
