import React from 'react'
import styles from './cta.module.scss'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <section className="full-container">
      <div className={styles.cta_section}>
        <Container>
          <Row className="align-items-center">
            <Col lg="9">
              <h2 className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting</h2>
              <p className="mb-0 text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer.</p>
            </Col>
            <Col lg="3" className="text-center text-lg-end mt-4 mt-lg-0">
              <Link to="/" className="theme-btn btn">Learn More</Link>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default Cta
