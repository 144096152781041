import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from "../../assets/css/footer.module.scss";
import { WhiteLogo } from '../../assets/images';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles.hmg_footer}>
      <Container>
        <Row>
          <Col lg="4" className={styles.hmg_footer_innr}>
            <Link to="/"><img src={WhiteLogo} alt="footer logo" className={styles.footer_logo} /></Link>
            <p className="text-white">C/O Gurudev Fuels, Vidhansabha Road,<br className="d-none d-lg-block" />Mowa, Raipur, Chhattisgarh 492007 IN</p>
            <p><Link to="tel:+91 9244 124 010" className="text-white">+91 9244 124 010</Link></p>
            <div className={styles.footer_social_links}>
              <Link to="#" target="_blank"><FaFacebookF /></Link>
              <Link to="#" target="_blank"><FaTwitter /></Link>
              <Link to="#" target="_blank"><FaInstagram /></Link>
              <Link to="#" target="_blank"><FaYoutube /></Link>
            </div>
          </Col>
          <Col lg="4" className={styles.hmg_footer_innr}>
            <h4>Quick Links</h4>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contacts</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Terms and conditions</Link>
              </li>
            </ul>
          </Col>
          <Col lg="4" className={styles.hmg_footer_innr}>
            <div className={styles.hmg_footer_innr_right}>
              <h4>Book a Consultation for Free!</h4>
              <p>Lorem Ipsum is simply dummy text of the <br className="d-none d-lg-block" />printing and typesetting industry. Lorem <br className="d-none d-lg-block" />Ipsum has been the industry’s.</p>
              <Link to="#" className={styles.book_now_btn}>Book Now</Link>
            </div>
          </Col>
        </Row>
        <Row className={styles.copy_right_text}>
          <Col lg="6" md="8">
            <p className="text-center text-lg-start text-md-start text-white mb-0">© Copyright {new Date().getFullYear()} Gurudev Abrasives. All Rights Reserved</p>
          </Col>
          <Col lg="6" md="4">
            <p className="text-white text-center text-lg-end text-md-end mb-0 mt-3 mt-lg-0 mt-md-0">Website by <Link to="https://scrumdigital.com/"><strong>Scrum Digital</strong></Link></p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
