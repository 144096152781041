import React from 'react'
import styles from './terms-banner.module.scss'
import { Container, Row, Col } from 'react-bootstrap';
import { HmgBannerImg, HmgBannerBgImg } from '../../assets/images';

const HomeBanner = () => {
  return (
    <section className={styles.home_banner}>
      <Container>
        <Row className="justify-content-center">
          <Col lg="11">
            <div className={`${styles["home_banner_contnt"]} text-center`}>
              <h1>Terms and conditions</h1>
              <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
          </Col>
        </Row>
      </Container>
      <img src={HmgBannerBgImg} alt="banner bg" className={styles.home_banner_bg_img} />
    </section>
  )
}

export default HomeBanner
