import React, { useRef } from 'react'
import ContactBanner from '../components/contact/ContactBanner'
import ContactContent from '../components/contact/ContactContent'

const Contact = () => {
  return (
    <>
      <ContactBanner />
      <ContactContent />
    </>
  )
}

export default Contact
