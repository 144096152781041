import React, { useRef } from 'react'
import PrivacyBanner from '../components/privacy/PrivacyBanner'
import PrivacyContent from '../components/privacy/PrivacyContent'

const Privacy = () => {
  return (
    <>
      <PrivacyBanner />
      <PrivacyContent />
    </>
  )
}

export default Privacy
