import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import styles from './subscribe.module.scss';

const Subscribe = () => {
  return (
    <div className={styles.subscribe}>
      <Container>
        <Row className="justify-content-center">
          <Col xl="8" lg="12">
            <div className="sub-heading text-center">
              <h4 className="text-uppercase">Subscribe</h4>
              <h2>Subscribe To Get The Latest News About Us.</h2>
              <p>We recommended you subscribe to our newsletter, Enter your email below to get our daily update about us.</p>
            </div>
            <form>
              <Form.Control type="text" placeholder="Enter your email address…" />
              <button type="submit" className="theme-btn btn">Subscribe</button>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Subscribe
